<template>
    <ul :class="(navStyle=='mobile')?'site-nav-wrap':'site-menu js-clone-nav d-none d-lg-block' ">
        <li class="animated-link-dark text-info "><router-link to="/" style="color:#353535">{{ $t("layout.nav.home") }}</router-link></li>
        <li class="animated-link-dark col-md-12 col-xl-2  txt-section"><router-link to="/propiedades-en-venta" style="color:#353535">{{ $t("layout.nav.sale_properties") }}</router-link></li>
        <li class="animated-link-dark col-md-12 col-xl-2   txt-section"><router-link to="/propiedades-en-renta" style="color:#353535">{{ $t("layout.nav.rent_properties") }}</router-link></li>
        <li class="animated-link-dark bor-btn col-md-12 col-xl-3 txt-section"><router-link to="/contacto" style="color:rgb(255, 255, 255);">Vende o renta con nosotros</router-link></li>
        <li class="animated-link-dark col-md-12 col-xl-2  txt-section">
            <a @click="openPDF" target="_blank" style="color:#353535; cursor: pointer;">Aviso de Privacidad</a>
        </li> 
        <li class="animated-link-dark" style="color:#353535">
            <LocaleSwitcher />
        </li>
    </ul>
</template>
<script>
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
    props:{
        navStyle:{
            type:String,
            required:true
        }
    },
    components: {
        LocaleSwitcher,
    },
    methods: {
    openPDF() { 
        const pdfPath = "https://sincroniai.com/documents/AVISO DE PRIVACIDAD.pdf"; 
        window.open(pdfPath, '_blank');
        }
    }
}
</script>
<style>
.bor-btn{
    border: 1px solid;
    border-color: #28b6ed;
    border-radius: 10px;
    background:#28b6ed;

}
@media screen and (min-width: 1200px) {
    .txt-section {
        text-align: center;
    }
}
@media screen and (max-width: 1199px) {
    .txt-section {
        padding-left:0px;
    }
}
</style>

