<template>

    <div class="site-navbar site-navbar-white mt-4">
      <div class="container py-1">
        <div class="row align-items-center">
          <div class="col-8 col-md-8 col-lg-3 text-center">
          <router-link class="" to="/">
            <img
              v-if="isHomePage && info"
              class="home-logo"
              :src="info.logo"
              :alt="info.alt"
            />
            <br>
            <span v-if="isHomePage" style="color: #353535;">ENLAZAMOS OPORTUNIDADES</span>
          </router-link>
        </div>

          <div class="col-4 col-md-4 col-lg-9">
            <nav
              class="site-navigation text-right text-md-right"
              role="navigation"
            >
              <div class="d-inline-block d-lg-none ml-md-0 mr-auto py-3">
                <a href="#" class="site-menu-toggle js-menu-toggle "
                  ><span class="icon-menu h3"></span
                ></a>
              </div>

              <Navbar navStyle="fullScreen"/>
            </nav>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/layout/Navbar.vue";
export default {
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),

    isHomePage() {
    return this.$route.path === '/';
  },
  }
};
</script>

<style scoped>
.home-logo {
  width: 8vw;
}
@media only screen and (max-width: 991px) {
  .home-logo {
    width: 40%;
  }
}
.site-navbar-white{
  background-image: linear-gradient(#ffffffe0, transparent);
  color:black
}
</style>