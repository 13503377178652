<template>
  <div :class="[(nameRoute!='Agent')?'col-lg-4':'col-lg-12',  'mb-4']">
    <div class="team-member text-center">
      <img  @click="goToAgent()" :src="agent.image" class="img-fluid rounded mb-4 imagenA"/>

      <div class="text ">
        <h2 class="mb-2 font-weight-light text-black h4">
          {{ agent.fullname }}
        </h2>
        <p class="bio-agent" v-if="agent.biografia">
          {{ agent.biografia }}
        </p>
        <span class="d-block mb-3 text-white-opacity-05">{{
          agent.cellphone
        }}</span>
        <span class="d-block mb-3 text-white-opacity-05">{{
          agent.email
        }}</span>
        <p>
          <a v-if="agent.fb!=0" :href="agent.fb" class="text-black p-2"
            ><span class="icon-facebook"></span
          ></a>
          <a v-if="agent.instagram!=0" :href="agent.instagram" class="text-black p-2"
            ><span class="icon-instagram"></span
          ></a>
          <a v-if="agent.tweet!=0" :href="agent.tweet" class="text-black p-2"
            ><span class="icon-twitter"></span
          ></a>
          <a v-if="agent.linkedin!=0" :href="agent.linkedin" class="text-black p-2"
            ><span class="icon-linkedin"></span
          ></a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    agent: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formatBiography() {
      return this.agent.biografia.substring(0, 125) + "...";
    },
    nameRoute() {
      return this.$route.name;
    },
  },
  methods: {
    goToAgent() {
      //this.$store.dispatch('setAgentActive',this.agent);

      let nameAgentFilt = services.normalize(this.agent.fullname);
      let folio = this.agent.folio;
      this.$router.push({
        name: "Agent",
        params: {
          folio,
          nameAgentFilt,
        },
      });
    },
  },
};
</script>


<style scoped>
.imagenA {
    width: 300px !important;
    height: 255px !important;
    object-fit:contain;
}
.bio-agent{
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
