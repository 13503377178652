
<template lang="html">
	<!--<ip-search v-if="searchCompleted" :properties="propertiesFound"></ip-search>-->
	<div>
		<!-- Slider
		================================================== -->
		<HomeCarousel :items="items"/>

    <!-- Search
		================================================== -->
    <SearchForm />

		<!-- Properties sold
		================================================== -->
    <div>
      <div class="site-section site-section-sm pb-0 bg-light">
        <div class="container">
          <div class="row">
            <h1 class="d-inline-block  px-3 mb-3 property-offer-type rounded"> {{ $t("pages.home.title_properties") }} </h1>
          </div>
        </div>
      </div>

      <div class="site-section site-section-sm bg-light">
        <div class="container">
          <div class="row mb-5 justify-content-center">
            <SectionLoader v-if="!properties"/>
            <PropertyCard
              v-else
              v-for="(item, i) in properties"
              :key="item.folio"
              :name="item.folio"
              :index="i"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

		<div class="site-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <div class="site-section-title">
              <h2>  {{$t("pages.home.about_section.title")}}</h2>
            </div>
            <br>
            <h2 v-if="info.about_us == 0 & info.about_us_eng == 0 || info.about_us == 0"
              style="text-align: center;font-size: 28px;font-weight: 100;" class="d-inline-block  px-3 mb-3 property-offer-type rounded">
              {{$t("pages.about.company.description")}}
            </h2>
            <h2 v-else
              style="text-align: center;font-size: 28px;font-weight: 100;" class="jump-line d-inline-block  px-3 mb-3 property-offer-type rounded">
              <About 
                :aboutx="activeLocale == 'es' || !info.about_us_eng
                ? info.about_us	 : info.about_us_eng"
                />
            </h2>
            <hr>
            <h2 v-if="info.our_slogan == 0 & info.our_slogan_eng == 0" 
                  > {{$t("pages.about.slogan")}}</h2
                >
            <h2 v-else class="jump-line">
              <Slogan 
                :sloganx="activeLocale == 'es' || !info.our_slogan_eng
                ? info.our_slogan	 : info.our_slogan_eng"
                />
            </h2>

          </div>
        </div>
        <br>
        <br>

        <div class="row" style="top:10px;">
          <div class="col-md-6 col-lg-4">
            <div  class="service text-center">
               <span class="icon flaticon-house my-1" style="font-size:55px"></span>
                <h2 style="text-align: center;">{{$t("pages.about.mision.title")}}</h2>
                <p v-if="info.our_mission == 0 & info.our_mission_eng == 0" 
                  style="text-align:Justify;font-size:16px">
                  {{$t("pages.about.mision.description")}}
                </p>
                <p v-else
                  style="text-align:Justify;font-size:16px" class="jump-line">
                  <Mision 
                    :misionx="activeLocale == 'es' || !info.our_mission_eng
                    ? info.our_mission	 : info.our_mission_eng"
                    style="font-size:16px;line-height:2;text-align: justify;"
                    />
                </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="service text-center">

               <span class="icon far fa-building my-3"></span>
							 <br>
                <h2 class="mb-2" style="text-align: center;">{{$t("pages.about.vision.title")}}</h2>

                <p v-if="info.our_vision == 0 & info.our_vision_eng == 0"
                  style="text-align:Justify; font-size:16px" >
                    {{$t("pages.about.vision.description")}}
                </p>
                <p v-else
                  style="text-align:Justify; font-size:16px" class="jump-line">
                  <Vision 
                    :visionx="activeLocale == 'es' || !info.our_vision_eng
                    ? info.our_vision	 : info.our_vision_eng"
                     style="font-size:16px;line-height:2;text-align: justify;"
                    />
                </p>
                <p> <router-link class="read-more"  to="/nosotros">{{$t("pages.home.about_section.button")}} </router-link></p>

            </div>
          </div>


          <div class="col-md-6 col-lg-4">
            <div class="service text-center">
              <span class="icon far fa-handshake my-3"></span>
                <h1 class="mb-2" style="text-align: center;">VALORES</h1>

                <p v-if="info.our_values == 0 & info.our_values_eng == 0" >
                  <ul style="font-size:16px;line-height:2;text-align: left;">
                    <li> <b>Pasión </b></li>
                    <li> <b>Integridad </b></li>
                    <li> <b>Vocación de servicio </b></li>
                    <li> <b>Mejora continua </b></li>
                    <li> <b>Profesionalismo </b></li>
                  </ul>
                </p>
                <p v-else class="jump-line">
                  <Values 
                    :valuesx="activeLocale == 'es' || !info.our_values_eng
                    ? info.our_values	 : info.our_values_eng"
                     style="font-size:16px;line-height:2;text-align: left;"
                    />
                </p>
               </div>
            </div>

          </div>
        </div>
      </div>

		<div class="site-section site-section-sm bg-light">
      <div class="container">
        <div class="row mb-5 justify-content-center">
					<AgentCard
	          v-for="(item, i) in agents"
	          :key="item.folio"
	          :name="item.folio"
	          :index="i"
	          :agent="item"
	        />
        </div>
      </div>
    </div>

    <!-- Apartado de comentarios de clientes -->
    <div class="seccion container" style="display:none;">
      <div class="box-clientes mb-4">
          Lo que nuestros clientes dicen
      </div>
      <div class="text-center pt-4 mb-5 siz">
        Recopilamos la opinión de nuestros clientes
      </div>


  <div class="row mb-5">
    <div class="col-12 col-md-4 text-center mb-5 mt-3">
      <div class="container">
        <div class="speech-bubble">
          <div class="triangle"></div>
          <p class="p-3 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        <div class="mt-3 mb-5 text-left">
          <div class="row">
            <div class="col-3">
              <img class="home-logo img-fluid rounded-circle " :src="info.logoClean" :alt="info.alt" />
            </div>
            <div class="col-9">
              <p class="mb-0 txt-exp"> Jenni Wilson </p>
              <p>Apartamento Rentado</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 text-center mb-5 mt-3">
      <div class="container-div">
        <div class="speech-bubble">
          <div class="triangle"></div>
          <p class="p-3 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div class="mt-3 mb-5 text-left">
          <div class="row">
            <div class="col-3">
              <img class="home-logo img-fluid rounded-circle " :src="info.logoClean" :alt="info.alt" />
            </div>
            <div class="col-9">
              <p class="mb-0 txt-exp"> Thomas Anderson</p>
              <p>Casa comprada</p>
            </div>
          </div>
        </div>
      </div>
  </div>

    <div class="col-12 col-md-4 text-center mb-5 mt-3">
      <div class="container">
        <div class="speech-bubble">
          <div class="triangle"></div>
          <p class="p-3 text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div class="mt-3 mb-5 text-left">
          <div class="row">
            <div class="col-3">
                <img class="home-logo img-fluid rounded-circle " :src="info.logo" :alt="info.alt" />
            </div>
            <div class="col-9">
              <p class="mb-0 txt-exp"> Robert Langdon</p>
              <p>Casa vendida</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



    </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import HomeCarousel from "@/components/carousel/HomeCarousel.vue";
import SectionLoader from "@/components/layout/SectionLoader.vue";
import AgentCard from "@/components/agents/AgentCard.vue";
import About from "@/components/about/About.vue";
import Slogan from "@/components/about/Slogan.vue";
import Mision from "@/components/about/Mision.vue";
import Vision from "@/components/about/Vision.vue";
import Values from "@/components/about/Values.vue";
export default {
  components: {
    HomeCarousel,
    SearchForm,
    PropertyCard,
    SectionLoader,
		AgentCard,
    About,
    Slogan,
    Mision,
    Vision,
    Values,
  },
  computed: {
    ...mapGetters({
      items:'_getHomeSlide',
      properties:'_getHomeProperties',
			agents:'_getTeam',
      error:'_getError',
      info: "_getInfoCompany"
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  created(){
    this.$store.dispatch('getHomeSlide');
    this.$store.dispatch('getHomeProperties');
		this.$store.dispatch('getTeam');
  }
};
</script>

<style>
.listing-item {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 10px;
}
#propImg {
  width: 100%;
  height: 100%;
}
.jump-line{
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: justify;
}
.jump-line p{
  text-align: justify!important;
}
/* estilos de apartado de clientes comentarios */
.box-clientes{
  margin-left: 50%;
  transform: translate3d(-50%,-50%,0);
  width: calc(100% - 40%);;
  display: inline-block;
  padding: 10px 20px; 
  color: #333; 
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); 
  text-align: center;
  font-size: 25px;
}

.speech-bubble {
  position: relative;
  background-color: #eeeeee;
  color: #333;
  border-radius: 5px;
  padding: 10px; 
  display: flex;
  flex-direction: column;
  height: 190px;
}

.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #eeeeee;
  top:100%;
  left: 10px;
}

.speech-bubble p {
  overflow: hidden;
}
.img-pequena {
    width: auto;
    height: 20px; 
}
.txt-exp{
  color: #28b6ed;
}
.siz{
  font-size: 25px;
}
/* fin comentarios clientes */


</style>
