<template>
<div class="site-blocks-cover inner-page-cover overlay aos-init aos-animate parallax-banner">
  <div class="logoBanner">
      <img src="/images/sincronia-banner.png" alt="Descripción de la imagen" class="w-100 d-none d-sm-block"/>
  </div>
  
      <div class="container">
        <div class="row align-items-center justify-content-center text-center">
          <div class="col-md-10">
            
            <h1 class="mb-2">{{$t(`${pageTitle}`)}}</h1>
          </div>
        </div>
      </div>
    </div>

</template>
<script>
import { mapGetters } from "vuex";
export default {
    props:{
        image:{
            type:String,
            required:true
        },
        pageTitle:{
            type:String,
            required:true
        }
    },
    computed:{
    ...mapGetters({
          info: "_getInfoCompany",
        }),
    },

}
</script>
<style scoped>
.parallax-banner{
    height: calc(23vh);
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
}
.site-blocks-cover, .site-blocks-cover .row {
  min-height: 150px;
}
.end-h{
  align-items: center; 
  text-align: center;
  color: rgb(46, 11, 11);
  text-shadow: none;
}
.end-class{
  margin-bottom: 10%;
}
h1{
    font-size: 1.2rem;
  }
@media (min-width:768px) {
  h1{
    font-size: 2.7rem;
  }
  .parallax-banner{
    /* height: calc(60vh); */
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
}
.site-blocks-cover[data-v-a9f47d1e], .site-blocks-cover .row[data-v-a9f47d1e] {
  min-height: 250px;
}
}
@media (min-width:992px) {
  .parallax-banner{
    height: calc(40vh);
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
}
.site-blocks-cover, .site-blocks-cover .row {
  min-height: 250px;
}

  
}

@media (min-width:1200px) {
  .parallax-banner{
    height: calc(50vh);
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  
}
@media (min-width:1400px) {
  .parallax-banner{
    height: calc(50vh);
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  
}
@media (min-width:1500px) {
  .parallax-banner{
    height: calc(60vh);
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  
}
@media (min-width:991px) {
  h1{
    font-size:2.7rem;
  }
}
@media (min-width:1200px) {
  h1{
    font-size:3.8rem;
  }
}
/* @media only screen and (max-width:767px){
  h1{
    font-size: 1.2rem;
  }
} */
.logoBanner{
    display: flex; 
    align-items: center; 
    justify-content: center;
    /* height: calc(49vh); */
}
@media only screen and (max-width:767px) {
  .logoBanner{
    width: 100%;
    height: calc(26vh);
    background-image: url('/images/sincronia-banner.png');
    background-position: center;
    background-size: cover;
  }
}
</style>
