<template>
  <div>
    <div class="site-section site-section-sm pb-0">
      <div class="container">
        <div class="row">
          <!-- Search
          ==================================================-->
          <SearchForm />
        </div>
      </div>
    </div>


    <div v-if="$t(`${$route.meta.pageTitle}`) == 'Propiedades en venta' || $t(`${$route.meta.pageTitle}`) == 'Properties for sale'" class="wrapper">
      <a href="https://socasesores.com/simulador-credito-hipotecario/?q=W7N3D" class="creditohipo" target="_blank" @mouseover="showMessage = true" @mouseleave="showMessage = false">
        <img src="../../../public/images/soc_blanco.png" alt="Descripción de la imagen" class="soc section-float  ia-static"/>
      </a>
      <div class="message-credit" v-show="showMessage">
        <span v-if="showCloseButton" class="close-btn" @click="closeMessage()">&times;</span>
          Simula tu <a href="https://socasesores.com/simulador-credito-hipotecario/?q=W7N3D" target="_blank" style="color: #006D4E; font-weight: bold; ">CRÉDITO HIPOTECARIO </a>
      </div>
      <div class="section-shadow"></div>
    </div>
    
    <br />
    <div class="container">
      <h1>{{$t(`${$route.meta.pageTitle}`)}}</h1>
    </div>
    <br />
    <NotResults v-if="error==204 && !propertiesListpropertiesList" code="204"/>
    <div v-else class="container">
      <SectionLoader v-if="!propertiesList" />
      <div v-else class="row mb-5">
        <PropertyCard
          v-for="(item, i) in propertiesList"
          :key="item.folio"
          :name="item.folio"
          :index="i"
          :item="item"
        />
      </div>
    </div>
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import SectionLoader from "@/components/layout/SectionLoader.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import NotResults from '@/components/layout/NotFound';
export default {
  components: {
    SearchForm,
    PropertyCard,
    SectionLoader,
    NotResults
  },
  data() {
    return {
      type:0,
      getList:{
        "1":"_getProperties",
        "2":"_getRentalsProperties",
        "4":"_getSaleProperties",
      },
        showMessage: true,
        showCloseButton: true
    };
  },
  computed: {
    ...mapGetters({
        error: "_getError",
    }),
    propertiesList(){
      let list = this.getList;
      let index = this.type;
      if(index>4){
        index=1;
      }
      return this.$store.getters[list[index]];
    }
  },
  watch: {
    '$route' (to, from) {
      this.definePage();
    }
  },
  mounted(){
      this.definePage();
  },
  methods:{
    definePage(){

          /*Types of lists:
            ShareProperties: -------- | code in route
            normalProps: ------------ | 1
            LongTerm Rentals Props: - | 2
            Vacational Rentals Props: | 3
            Sale properties: -------- | 4
            luxury: ----------------- | 5
          */
      if (this.$route.params.codeShareProperties) {
        this.type = this.$route.params.codeShareProperties;
      }else if(this.$route.name == "RentProperties"){
        this.type = 2;
      }else if(this.$route.name == "SaleProperties"){
          this.type = 4;
      }else{
          this.type = 1;
      }
      this.$store.dispatch("getProperties", this.type);
    },
    closeMessage() {
      this.showMessage = false;
      this.showCloseButton = false;
    }
  }
};
</script>

